import React, { useState } from 'react'
import ProjectSummary from './ProjectSummary'
import stealer from "../codePreviews/stealer.py"
import DiGraph from "../codePreviews/DiGraph.cpp"
import Player from "../codePreviews/Player.java"
import ship779 from "../pictures/ship779.png"
import projectmana from "../codePreviews/application.py"
import clcpp from "../codePreviews/CLmain.cpp"
import runcode from "../codePreviews/runCode.cpp"





export default function Projects() {

  const [preview,setPreview] = useState(false);
  const [imgPreview,setimgPreview] = useState(false);
  const [code,setCode] = useState();
  const [shImage,setshowImage] = useState(false);
  const [image,setImage] = useState();


  const onExit = () => {
    setPreview(false);
    setimgPreview(false);
    setshowImage(false);
    setImage("");
    setCode("");
  }

  const showImage = () => {
    if(shImage === false){
      setshowImage(true);
      setPreview(false);
    }
    else{
      setshowImage(false);
      setPreview(true);
    }
  }
  
  return(
      <div>
        <div className="general-wrapper">
          {!preview && !shImage && 
          <div className="project-wrapper">
            <ProjectSummary id="ProjectMana" title="ProjectManagement" file={projectmana} setPreview= {setPreview} setCode={setCode} project_description="The web application is intended to provide a way of managing company application-relevant information on customer-specific projects."/>
            <ProjectSummary id="DiGraph" title="Dijkstra" file={DiGraph} setPreview= {setPreview} setCode={setCode} project_description="Dijkstra’s shortest path algorithm. Given a graph and a source vertex in the graph, find the shortest paths from the source to all vertices in the given graph."/>
            <ProjectSummary id="c++bot" title="Bot C++" file={runcode} setPreview= {setPreview} setCode={setCode} project_description="Project is currently in development. Bot for Windows with c&c server and client manager."/>
            <ProjectSummary id="py" title="Keylogger Py" file={stealer} setPreview={setPreview} setCode={setCode} project_description="A basic keylogger and credentials-stealer in python with an e-mail sending functionality."/>
            <ProjectSummary id="ShipGameJava" title="Battleship-Game-Java" file={Player} setPreview= {setPreview} image={ship779} setImage={setImage} setCode={setCode} setimgPreview={setimgPreview} project_description="A battleship game in Java (with UI Picture). "/>
            <ProjectSummary id="CppChat" title="Console-Chat-Cpp" file={clcpp} setPreview= {setPreview} setCode={setCode} project_description="A basic chat c++ console application for multiple clients."/>
          </div>
          }
          {preview &&  !shImage &&
                <div className="show-project-preview">
                    <div className="title">
                    <div className="file-title">preview</div> 
                    <i className="fa fa-times-circle" aria-hidden="true" onClick={onExit}></i>
                    {imgPreview && 
                      <i className="fa fa-image" aria-hidden="true" onClick={showImage}></i>
                    }
                  </div>
                  <textarea readOnly spellCheck="false" className="code" value={code}></textarea>
                </div>
            
          }
       </div>
       {shImage && !preview &&
              <div className="container_img">
                <div className="title">
                      <div className="file-title">preview</div> 
                      <i className="fa fa-times-circle" aria-hidden="true" onClick={onExit}></i>
                      {imgPreview && 
                        <i className="fa fa-image" aria-hidden="true" onClick={showImage}></i>
                      }
                    </div>
                 <img src={image}></img>
              </div>
          }
      </div> 
  );
}