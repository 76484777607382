import React, { useState,useEffect } from "react";


async function loadFile(file){
    return fetch(file)
    .then(data => {
        if(data.ok){
            return data.text();
        }
        else{
            return "file konnte nicht geladen werden."
        }
    })
   };
  

function ProjectSummary(props){

    const [data,setData]= useState();

    useEffect(() => {
        loadFile(props.file).then(data_load => setData(data_load));
      },[])

    const onclick = (e) =>{
        props.setPreview(true);
        props.setCode(data);

        if(props.id == "JavaChat"){
            props.setimgPreview(true);
            props.setImage(props.image);
        }
        if(props.id == "ShipGameJava"){
            props.setimgPreview(true);
            props.setImage(props.image);
        }       
    }
    
    
    return(
            <div className="project-summary">
                    
                        <div className="mangekyou">
                            <div className="point"></div>
                            <div className="point"></div>
                            <div className="point"></div>
                            <div className="circle"></div>
                        </div> 
                    
                    <span className="project-title">{props.title}</span>
                    <div className="project-description">
                    {props.project_description}
                    </div>
                    <button className="btn" onClick={onclick}>
                        <span className="btn__content">code preview_</span>
                        <span className="btn__glitch"></span>
                    </button>       
            </div>
        
        
    )// return 

}

export default ProjectSummary;