import React, {useState} from "react"
import {BrowserRouter,Route, Routes,Navigate} from 'react-router-dom'
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Projects from "./components/Projects";
import Home from "./components/Home";
import { app } from './config/firebase-config';




function useToken(){
   const getToken = () =>{
    const tokenvalue = sessionStorage.getItem('token');
    return tokenvalue;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}



function App() {

    const {token ,setToken} = useToken();

    if(!token) {
      return <Login setToken={setToken} />
    }
    
    return (
      <div className="wrapper">
        <BrowserRouter>
        <div className="navbar">
          <Navbar  setToken={setToken} />
        </div>

         <Routes>
            <Route path="/projects" element={<Projects/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/login" element={token ? <Navigate to="/" /> : <Login setToken={setToken} />}/>
            <Route path="*" element={ <Navigate to="/" />}/>
         </Routes>
        </BrowserRouter>
        <footer>created by DH</footer>
      </div>
      
      
    );//return
  }



export default App;
