import React from "react"
import { NavLink } from "react-router-dom"

function logout({setToken}){
    sessionStorage.removeItem('token');
    setToken("");
}

function Navbar() {

      function barWidth(){
        var d =document.getElementsByClassName("cont_ainer");
        d[0].classList.toggle("change");	
            }	
                

        return(
        
            <div id="container" className="cont_ainer change">
                <nav >
                    <div id="circlemain" className="circle_main" >
                
                    <div className="arc1 line1">
                    <div className="arc1 line2">
                        <div className="arc1 line3">
                        </div>
                    </div>
                    </div>
                
                
                    <div className="reactor">
                        <div className="navcontainer" onClick={barWidth}>
                            <div className="hamContainer">
                                <div className="hbar1"></div>
                                <div className="hbar2"></div>
                                <div className="hbar3"></div>
                                <div className="hbar4"></div>
                                <div className="hbar5"></div>
                            </div>
                            <span className="reacttext">DH</span>
                        </div>	
                    </div>
            
            
                    <span  id="barleft" className="bar_left ">
                        <ul className="nav_top" id="navtop">
                            <li><NavLink to='/'>Home <i className = "fa fa-home"></i></NavLink></li>
                            <li><a href='https://github.com/dh96' target="_blank" >Github <i className = "fa fa-github"></i></a></li>
                         
                            
                    </ul>	
                    </span>
                
                
                    <span id="barright" className="bar_right">	
                        <ul className="nav_bottom" id="navbottom">
                            <li><NavLink to= '/login' onClick={logout}>Logout <i className = "fa fa-sign-out"></i></NavLink></li>
                            <li><NavLink to='/projects'>Pro <i className = "fa fa-cogs"></i></NavLink></li>
                        </ul>
                    </span>
                    </div>
                </nav>
            </div>

        ) // return         
}

export default Navbar
    