import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCp_Y_s-CxCDEPZkC9mwYngWWzD-wCbjm0",
  authDomain: "den4ik-webapp.firebaseapp.com",
  projectId: "den4ik-webapp",
  storageBucket: "den4ik-webapp.appspot.com",
  messagingSenderId: "142924481629",
  appId: "1:142924481629:web:08a52b2374c3b34de7f9b4"
};

const app = initializeApp(firebaseConfig);
