import React, {useState} from "react";
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


async function loginUser(credentials,setToken,setSubmitting) {
      const authentication = getAuth();
      signInWithEmailAndPassword(authentication, credentials.email, credentials.password)
        .then((response) => {
            setTimeout(() =>{
                toast.success('login successful', {autoClose: false,closeButton: false});
                setTimeout(()=>{
                setToken(response._tokenResponse.refreshToken);
                },2065);
            },5000);   
            
        })
        
        .catch (err =>{
            setTimeout(() =>{
                document.getElementById("form").style.opacity=1;
                setSubmitting(false);
                toast.error('wrong credentials', {autoClose: 3000,closeButton: false});
            },4000);   
        })
    
   }



function Login({setToken}){
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [submitting, setSubmitting] = useState(false);
    

    const handleSubmit = async e =>{
        e.preventDefault();
        document.getElementById("form").style.opacity=0;
        setSubmitting(true);
        loginUser({
            email,
            password
        },setToken,setSubmitting); 
    }
      
   
        return(
            <div>
            <ToastContainer />
            <form id="form" onSubmit={handleSubmit}>
                { !submitting &&
                    <div>
                    <div className="form-text">Sign-In</div>
                    <i id="log_lock" className="fa fa-lock"></i>
                        <label>
                            <input type="text" id="name" required onChange={e => setEmail(e.target.value)}/>
                            <div className="label-text">E-mail</div>
                        </label>
                        <label>
                            <input type="password" id="password" required onChange={e => setPassword(e.target.value)}/>
                            <div className="label-text">Password</div>
                        </label>
                        <button className="animated-button1" type="submit">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        submit
                        </button>

                    </div>
                }
                
            </form>
            <footer>created by DH</footer>
            {submitting && 
                <div>
                <div className="loader">
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    <span className="loader_span"></span>
                    </div>

                  <div className="loading-text">loading...</div>
                  <footer>created by DH</footer>
                 </div>   
                



            }
           </div> 
           
        ) //return
        
    }



export default Login