import React, {useEffect} from 'react';



export default function Home() {


  return(
    <div className="home-wrapper">
      <div className="about">
        <a className="style_links">
					<span>A</span>
          <span>b</span>
          <span>o</span>
          <span>u</span>
          <span>t</span>		
				</a>
        <p>
         Hello, World! Welcome to my page. My name is Dennis Halter and i'm a computer science student.
         I like to code things and always try to expand my horizon.
         My focus is especially on IT security and sometimes i enjoy to challange myself in other projects.
        </p>
      </div>

      <div className="bio">
      <a className="style_links" >
					<span>B</span>
          <span>i</span>
          <span>o</span>      		
				</a>
        <ul>
          <li>1996  - Born in a village near Moscow Russia</li>
          <li>2002  - Moved to Germany</li>
          <li>2013  - Finished secondary school</li>
          <li>2016  - Finished A-level</li>
          <li>2016  - Started studying computer science at university(Hochschule Niederrhein Krefeld)</li>
          <li>2017  - Work and travel </li>
          <li>2022 to present - nearly finished university</li>
        </ul>
      </div>

      <div className="love">
      <a className="style_links">
          <span>I</span>
					<span>L</span>
          <span>o</span>
          <span>v</span>
          <span>e</span>

				</a>
        <p>Music, technology, coding, building pcs, gaming, traveling & learning new things.</p>
      </div>

      <div className="contact">
      <a className="style_links">
					<span>C</span>
          <span>o</span>
          <span>n</span>
          <span>t</span>
          <span>a</span>
          <span>c</span>		
          <span>t</span>		
				</a>
        <p>dennishalter@outlook.de</p>
      </div>

      
    </div>
  );
}
